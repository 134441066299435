export const BANDS = ["160m", "80m", "40m", "30m", "20m", "17m", "15m", "12m", "10m", "6m"];

export function getAmateurBand(frequency: number): string | null {
  if (frequency >= 1.8 && frequency <= 2.0) {
    return "160m";
  } else if (frequency >= 3.0 && frequency <= 4.0) {
    return "80m";
  } else if (frequency >= 5.0 && frequency <= 5.4) {
    return "60m";
  } else if (frequency >= 7.0 && frequency <= 7.3) {
    return "40m";
  } else if (frequency >= 10.0 && frequency <= 10.15) {
    return "30m";
  } else if (frequency >= 14.0 && frequency <= 14.35) {
    return "20m";
  } else if (frequency >= 18.0 && frequency <= 18.168) {
    return "17m";
  } else if (frequency >= 21.0 && frequency <= 21.45) {
    return "15m";
  } else if (frequency >= 24.0 && frequency <= 24.99) {
    return "12m";
  } else if (frequency >= 28.0 && frequency <= 29.7) {
    return "10m";
  } else if (frequency >= 50.0 && frequency <= 60.0) {
    return "6m";
  } else {
    return null; // Frequency does not match any known amateur band
  }
}