import { useCallback, useEffect, useState, useRef } from 'react';

const dbName = "callsignDatabase";
const storeName = "callsigns";

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "callsign" });
      }
    };
  });
};

function throttle(callback, limit) {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall >= 1000 / limit) {
      lastCall = now;
      return callback.apply(this, args);
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(callback.apply(this, args));
        }, 1000 / limit - (now - lastCall));
      });
    }
  };
}


const useCallsignLookup = () => {
  const [db, setDb] = useState(null);
  const fetchRef = useRef();
  const dbReadyPromiseRef = useRef();

  useEffect(() => {
    //console.log("useCallsignLookup useEffect");
    let mounted = true;
    if (!db) {
      openDB().then(database => {
        if (mounted) {
          //console.log("database", database);
          //console.log("openDB DB opened");
          setDb(database);
          dbReadyPromiseRef.current = Promise.resolve(database);
        }
      }).catch(error => {
        console.error("Failed to open the database: ", error);
        dbReadyPromiseRef.current = Promise.reject(error);
      });
    }
    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    fetchRef.current = throttle(async (callsign) => {
      const response = await fetch(`https://www.hamqth.com/dxcc.php?callsign=${callsign}`);
      const xml = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, "text/xml");
      const dxcc = xmlDoc.getElementsByTagName("dxcc")[0];
      const continent = dxcc.getElementsByTagName("continent")[0].textContent;
      const adif = dxcc.getElementsByTagName("adif")[0].textContent;

      return { continent, adif };
    }, 60);
  }, []);

  const callsignDbReady = useCallback(() => {
    return dbReadyPromiseRef.current || Promise.reject('Database initialization not started');
  }, []);

  const callsignLookup = useCallback((callsign) => {
    //console.log("begin lookup callsign: ", callsign);
    return callsignDbReady().then(db => {
      //console.log("callsignDbReady true, starting lookup");
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(callsign);

      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          if (request.result) {
            console.log("local callsign lookup success: ", request.result);
            resolve(request.result);
          } else {
            //console.log("local callsign lookup failed: ", request.result);
            // local callsign lookup success:  Object { continent: "NA", adif: "291", callsign: "KB7GF" }
            fetchRef.current(callsign).then(data => {
              const transaction = db.transaction(storeName, 'readwrite');
              const store = transaction.objectStore(storeName);
              store.add({ ...data, callsign });
              console.log("fetched data", data);
              // Object { continent: "NA", adif: "291" }
              resolve({ ...data, callsign });
            }).catch(reject);
          }
        };
        request.onerror = () => reject(request.error);
      });
    });
  }, [callsignDbReady]);

  return { callsignLookup, callsignDbReady };
};

export default useCallsignLookup;
