import React, { useEffect, useState } from 'react';
import { TableView, TableHeader, TableBody, Cell, Row, Column, Footer, Button, ComboBox, Item, Flex, Badge, ProgressCircle } from "@adobe/react-spectrum";
import useCallsignLookup from '../hooks/useCallsignLookup';
import { useSearchParams } from '@resourge/react-search-params';
import { BANDS, getAmateurBand } from '../utils/Ham';


interface BandStat {
  band: string;
  freq: string;
  continents: string[];
  report_count: number;
  avg_distance: number;
}

interface BandQth {
  [band: string]: {
    continents: string[];
    dxcc: string[];
  }
}

interface WsprSweetNothingsParams {
  callsign?: string;
  mins: number;
  report_count: number;
}

const presetCalls = [
  { sign: "AA7EE", location: "West Oakland, CA" },
  { sign: "K6SH", location: "Oakland Hills, CA" },
  { sign: "W6VYC", location: "Brisbane, CA" },
  { sign: "W6EXT", location: "Los Altos, CA" },
  { sign: "KS6M", location: "Oakland Hills, CA" }
];

export default function WsprSweetNothings() {
  const [params, setParams] = useSearchParams<WsprSweetNothingsParams>(
    ({ url }) => window.history.replaceState(null, '', url.href),
    { mins: 60, report_count: 10 }, // default params
    {} // config
  )
  const { callsignLookup } = useCallsignLookup();


  const [data, setData] = useState<BandStat | null>(null);
  const [qthData, setQthData] = useState<BandQth | null>(null)
  const [error, setError] = useState("");
  const [shouldRenderTable, setShouldRenderTable] = useState(false);
  const [presetCallsignDB, setPresetCallsignDB] = useState<any[]>([]);

  useEffect(() => {
    fetch(`https://db1.wspr.live/?query=select tx_sign, count(*) from wspr.rx WHERE tx_sign IN (${presetCalls.map(call => `'${call.sign}'`).join(', ')}) AND time >= now() - INTERVAL ${params.params.mins} MINUTE GROUP BY tx_sign ORDER BY count(*) desc FORMAT JSONCompact`).then(function(data) {
      if (data.ok) {
        data.json().then(json => {
          if (json.data.length > 0) {
            const hashy = json.data.reduce((hash: any, obj: any) => {
              hash[obj[0]] = obj[1];
              return hash;
            }, {});
            setPresetCallsignDB(presetCalls.map(presetCall => {
              return {
                sign: presetCall.sign,
                location: presetCall.location,
                count: hashy[presetCall.sign] || 0
              }
            }))
          }
        })
      }
    });

  }, []);

  useEffect(() => {
    console.log("data", data, "qthData", qthData);
    if (data && qthData) {
      setShouldRenderTable(true);
    }
  }, [data, qthData]);

  useEffect(() => {
    console.log("callsign", params.params.callsign);
  }, [params.params.callsign]);

  useEffect(() => {
    if (params.params.callsign && !data) {
      console.log("fetching wspr.live data", params.params.callsign, data)
      const fetchReceptions = fetch(`https://db1.wspr.live/?query=select rx_sign, band, avg(distance), count(*) from wspr.rx WHERE tx_sign = '${params.params.callsign}' AND time >= now() - INTERVAL ${params.params.mins} MINUTE GROUP BY rx_sign, band ORDER BY avg(distance) desc FORMAT JSONCompact`)
        .then(response => response.ok ? response.json() : Promise.reject("Error fetching receptions"))
        .then(json => {
          if (json.data.length > 0) {
            const uniqueSigns = Array.from(new Set(json.data.map(item => item[0])));
            return Promise.all(uniqueSigns.map(callsignLookup))
              .then(qthResults => {
                return json.data.map(item => ({
                  sign: item[0],
                  freq: item[1],
                  band: getAmateurBand(item[1]),
                  continent: qthResults.find(qth => qth.callsign === item[0])?.continent,
                  dxcc: qthResults.find(qth => qth.callsign === item[0])?.adif,
                  distance: item[2]
                }));
              });
          } else {
            return [];
          }
        });

      const fetchDistanceReports = fetch(`https://db1.wspr.live/?query=SELECT band, count(*) AS report_count, avg(distance) AS avg_distance \
              FROM (\
                  SELECT band, rx_sign, max_distance AS distance, ROW_NUMBER() OVER (PARTITION BY band ORDER BY max_distance DESC, max_time DESC) AS rn\
                  FROM (\
                      SELECT band, rx_sign, MAX(distance) AS max_distance, MAX(time) AS max_time \
                      FROM wspr.rx \
                      WHERE tx_sign = '${params.params.callsign}' AND time >= now() - INTERVAL ${params.params.mins} MINUTE \
                      GROUP BY band, rx_sign \
                  ) AS grouped_distances \
              ) AS ranked_distances \
              WHERE rn <= ${params.params.report_count} \
              GROUP BY band \
              ORDER BY avg_distance DESC \
              FORMAT JSONCompact`)
        .then(response => response.ok ? response.json() : Promise.reject("Error fetching distance reports"))
        .then(json => {
          if (json.data.length > 0) {
            return json.data.map(item => ({
              band: getAmateurBand(item[0]),
              freq: item[0],
              report_count: item[1],
              avg_distance: item[2]
            }));
          } else {
            throw new Error(`Not enough receptions found for this callsign (${params.params.callsign}) and time range.`);
          }
        });

      Promise.all([fetchReceptions, fetchDistanceReports])
        .then(([items, data]) => {
          console.log("saving data", items, data);
          setQthData(BANDS.reduce((acc, band) => {
            acc[band] = {
              continents: Array.from(new Set(items.filter(station => station.band === band).map(station => station.continent))).filter(v => v && v.trim() !== "").sort(),
              dxcc: Array.from(new Set(items.filter(station => station.band === band).map(station => station.dxcc))).filter(v => v && v.trim() !== "" && v !== "0").sort()
            };
            return acc;
          }, {}));
          setData(data);
        })
        .catch(error => {
          setError(`Request error: ${error}`);
        });
    }
  }, [params.params.callsign]);

  const [tmpCallsign, setTmpCallsign] = useState("");
  const [tmpCallsignInput, setTmpCallsignInput] = useState("");

  if (!params.params.callsign) {
    return (
      <Flex direction={"column"} alignItems="center" gap={"size-100"}>
        <ComboBox
          label={"Callsign"}
          width={"size-5000"}
          allowsCustomValue
          defaultItems={presetCallsignDB.map(({ sign, location, count }) => {
            return { id: sign, name: `${sign} - ${location} (${count} reports)` };
          })}
          onInputChange={(value) => {
            console.log("onInputChange", value);
            setTmpCallsignInput(value);
          }}
          onSelectionChange={selected => {
            console.log("onSelectionChange", selected);
            setTmpCallsign((selected || "").toString());
          }}>
          {item => <Item>{item.name}</Item>}
        </ComboBox>
        <Button variant='cta' onPress={() => {
          let nuCall = tmpCallsign;
          console.log("tmpCallsign, tmpCallsignInput", tmpCallsign, tmpCallsignInput);
          if (tmpCallsign !== tmpCallsignInput.split(" ")[0]) {
            console.log("using: ", tmpCallsignInput);
            nuCall = tmpCallsignInput;
          }
          // window.location.href = ((window.location.href.indexOf('?') !== -1 ? window.location.href : window.location.href.split("?")[0]) + `?callsign=${nuCall}&mins=${mins}&report_count=${report_count_target}`).replace(/\?+/g, '?');
          setParams({ callsign: nuCall })
        }}>Generate</Button>
      </Flex>
    )
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (shouldRenderTable) {
    const badgeColors = { AF: "seafoam", NA: "indigo", SA: "purple", OC: "fuchsia", EU: "magenta", AS: "yellow" };

    return (
      <>
        <h1>Band Report for {params.params.callsign}</h1>
        <h4>Time window: {params.params.mins} minutes</h4>
        <TableView>
          <TableHeader>
            <Column align='center'>Band</Column>
            <Column align='center'>MHz</Column>
            <Column align='center'>Continents</Column>
            <Column align='center'>Report Count</Column>
            <Column align='center'>Average Distance</Column>
          </TableHeader>
          <TableBody>
            {data.map((row) => {
              console.log("Bandrow: ", row, qthData[row.band]);
              return (
                <Row key={row.band}>
                  <Cell>{row.band}</Cell>
                  <Cell>{row.freq}</Cell>
                  <Cell>
                    <Flex direction="row" gap={8}>
                      {(qthData[row.band].continents.length > 0) ? qthData[row.band].continents.map((continent) => <Badge key={continent} variant={badgeColors[continent]}>{continent}</Badge>) : [<Badge key="foo" variant='neutral'>None?</Badge>]}
                    </Flex>
                  </Cell>
                  <Cell>{row.report_count}</Cell>
                  <Cell>{Math.round(row.avg_distance)}</Cell>
                </Row>
              )
            })
            }
          </TableBody>
        </TableView>
        <Button variant='cta' onPress={() => {
          window.location.href = window.location.href.split("?")[0];
        }}>Close Report</Button>
        <Footer>wspr sweet nothings powered by data from <a href="https://wspr.live/" target={"_blank"} rel={"noreferrer"}>wspr.live</a></Footer>
      </>
    )
  }

  return (
    <main>
      <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <ProgressCircle size='L' aria-label="Loading…" isIndeterminate />
      </Flex>
    </main >
  )
}