import { useEffect, useState } from "react";
import { Flex, Text, Badge } from '@adobe/react-spectrum';
import { createClient } from '@supabase/supabase-js'
const supabaseUrl = 'https://nogszorateqogyhvpvfb.supabase.co'
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5vZ3N6b3JhdGVxb2d5aHZwdmZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM4Njk2NzUsImV4cCI6MjAxOTQ0NTY3NX0.wSWw9TGH96oJpZel3K3YFHAHWzNNrvt8nEX64Haqlqw";
const supabase = createClient(supabaseUrl, supabaseKey || "")
import moment from 'moment'
import { getAmateurBand } from "../utils/Ham";

interface OnAirProps {
  callsign: string;
}

interface LastSeen {
  callsign: string;
  frequency: number;
  timestamp: Date;
  mode: string;
}

export default function OnAir(props: OnAirProps) {
  const [lastSeen, setLastSeen] = useState<LastSeen | null>(null)

  const fetchLastSeen = async () => {
    supabase
      .from('last_seen')
      .select('*')
      .then(({ data: last_seen, error }) => {
        if (last_seen && last_seen.length > 0) {
          setLastSeen({
            callsign: last_seen[0].callsign,
            frequency: last_seen[0].frequency,
            timestamp: last_seen[0].timestamp,
            mode: last_seen[0].mode
          });
        }
      })
  }

  useEffect(() => {
    // call last seen every minute
    const interval = setInterval(() => {
      fetchLastSeen();
    }, 60000);
    fetchLastSeen();
    return () => clearInterval(interval);
  }, []);

  if (!lastSeen) {
    return null;
  }

  let onAirBadge = null;
  // if on air within the last hour
  if (moment().diff(moment(lastSeen.timestamp), 'minutes') < 60) {
    onAirBadge = (
      <Badge variant="negative" aria-label={"Station recently on the air."}>ON AIR</Badge>
    );
  } else {
    onAirBadge = (
      <Badge variant="neutral" aria-label={"Station recently on the air."}>ON AIR</Badge>
    )
  }


  return (
    <Flex alignItems="center" direction={"column"} wrap>
      {onAirBadge}
      <Text>{lastSeen.mode} on {getAmateurBand(Math.round(lastSeen.frequency / 1000) / 1000)}</Text>
      <Text>{moment.utc(lastSeen.timestamp).local().fromNow().replace("minute", "min").replace("hour", "hr")}</Text>
    </Flex>

  )
}