import { useState, useEffect } from 'react';
import { SimpleAdif, AdifParser } from 'adif-parser-ts';
import { Button, TableView, TableHeader, Column, TableBody, Row, Cell, Text, Flex, View } from '@adobe/react-spectrum';
import { FileTrigger } from 'react-aria-components';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
// import L, { LatLngExpression } from "leaflet";
// import { Axis, Bar, Legend, Chart, Annotation, ReferenceLine } from '@adobe/react-spectrum-charts';
import { ResponsiveBar } from '@nivo/bar'
import "leaflet/dist/leaflet.css";

//import './App.css';

interface SeenCountries {
  [name: string]: {
    dxcc: string;
    confirmed: number;
    unconfirmed: number;
    total: number;
  };
}

interface SeenCountry {
  name: string;
  dxcc: string;
  confirmed: number;
  unconfirmed: number;
  total: number;
}

interface DailyStat {
  date: Date;
  "160m": number;
  "80m": number;
  "60m": number;
  "40m": number;
  "30m": number;
  "20m": number;
  "17m": number;
  "15m": number;
  "12m": number;
  "10m": number;
  "6m": number;
  [key: string]: any;
}

const recordConfirmed = (record: any) => {
  if (record.lotw_qsl_rcvd === 'Y' || record.app_qrzlog_status === 'C') {
    return true;
  }
  return false;
}

// const gridToCoords = (grid: string) => {
//   const longitudeBase = (grid.charCodeAt(0) - 'A'.charCodeAt(0)) * 20;
//   const latitudeBase = (grid.charCodeAt(1) - 'A'.charCodeAt(0)) * 10;

//   const longitudeSquare = parseInt(grid[2]) * 2;
//   const latitudeSquare = parseInt(grid[3]) * 1;

//   const longitudeSubSquare = (grid.charCodeAt(4) - 'A'.charCodeAt(0)) * (5 / 60);
//   const latitudeSubSquare = (grid.charCodeAt(5) - 'A'.charCodeAt(0)) * (2.5 / 60);

//   let longitudeExtendedSquare = 0, latitudeExtendedSquare = 0;
//   if (grid.length >= 8) {
//     longitudeExtendedSquare = parseInt(grid[6]) * (30 / 3600);
//     latitudeExtendedSquare = parseInt(grid[7]) * (15 / 3600);
//   }

//   const longitudeStart = longitudeBase + longitudeSquare + longitudeSubSquare + longitudeExtendedSquare - 180;
//   const latitudeStart = latitudeBase + latitudeSquare + latitudeSubSquare + latitudeExtendedSquare - 90;

//   const longitudeEnd = longitudeStart + (30 / 3600); // Increment by smallest division size (extended square)
//   const latitudeEnd = latitudeStart + (15 / 3600); // Increment by smallest division size (extended square)

//   return [
//     [latitudeStart, longitudeStart],
//     [latitudeEnd, longitudeEnd]
//   ];
// };

const gridToCoords = (grid: string) => {
  // Longitude calculation (first character)
  const lonIndex = grid.charCodeAt(0) - 'A'.charCodeAt(0);
  const longitudeStart = lonIndex * 20 - 180; // Each field represents 20 degrees

  // Latitude calculation (second character)
  const latIndex = grid.charCodeAt(1) - 'A'.charCodeAt(0);
  const latitudeStart = latIndex * 10 - 90; // Each field represents 10 degrees

  // Refinement with the second pair (square)
  // Each square refines the location to 2 degrees of longitude and 1 degree of latitude
  const lonSquare = parseInt(grid[2]) * 2;
  const latSquare = parseInt(grid[3]) * 1;

  // Final coordinates for the rectangle's southwest corner
  const finalLongitudeStart = longitudeStart + lonSquare;
  const finalLatitudeStart = latitudeStart + latSquare;

  // Calculate northeastern corner by adding the range of one square
  const finalLongitudeEnd = finalLongitudeStart + 2; // one square spans 2 degrees of longitude
  const finalLatitudeEnd = finalLatitudeStart + 1; // one square spans 1 degree of latitude

  return [
    [finalLatitudeStart, finalLongitudeStart], // Southwestern corner
    [finalLatitudeEnd, finalLongitudeEnd]      // Northeastern corner
  ];
};



const GridMap = ({ grids }) => {
  const polygons = grids.map(grid => {
    const [[swLat, swLon], [neLat, neLon]] = gridToCoords(grid);

    // if any of them are NaN, then the grid is invalid
    if (isNaN(swLat) || isNaN(swLon) || isNaN(neLat) || isNaN(neLon)) {
      console.log(`Invalid grid: ${grid}`);
      return null;
    }

    // Coordinates need to be a loop to form a closed polygon
    return [
      [swLat, swLon], // Southwest
      [neLat, swLon], // Northwest
      [neLat, neLon], // Northeast
      [swLat, neLon], // Southeast
      [swLat, swLon]  // Closing the loop by returning to the Southwest corner
    ];
  });

  // const polygons = grids.map(grid => gridToCoords(grid).map(coord => [coord[0], coord[1]]));

  return (
    <MapContainer center={[0, 0]} zoom={1} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {polygons.filter((polygon) => !!polygon).map((polygon, idx) => (
        <Polygon key={grids[idx]} pathOptions={{ color: 'blue', fillColor: 'blue', fillOpacity: 0.5 }} positions={polygon} />
      ))}
    </MapContainer>
  );
};

export default function AdifRentVisualizer() {
  const [logbook, setLogbook] = useState<SimpleAdif>({} as SimpleAdif);
  const [seenCountriesArray, setSeenCountriesArray] = useState<SeenCountry[]>([]);
  const [dailyStats, setDailyStats] = useState<DailyStat[]>([]);
  const [seenGrids, setSeenGrids] = useState<string[]>([]);
  const [sort, setSort] = useState<any>({
    column: 'total',
    direction: 'descending'
  });

  const doSort = (items: SeenCountry[]) => {
    const sortColumn = sort.column as keyof SeenCountry;
    const sortDirection = sort.direction;
    return items.sort((a: SeenCountry, b: SeenCountry) => {
      if (sortColumn === 'name') {
        return sortDirection === 'ascending' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      }
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'ascending' ? -1 : 1;
      } else if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (logbook.records instanceof Array) {
      // example date "20210901"
      const arrayOfLastXDays = (days: number) => {
        const today = new Date();
        const lastXDays = [];
        for (let i = 0; i < days; i++) {
          const date = new Date(today);
          date.setDate(today.getDate() - i);
          // console.log(date, date.toISOString());
          lastXDays.push(date.toISOString().slice(0, 10).replaceAll("-", ""));
        }
        //console.log("lastXDays", lastXDays);
        return lastXDays;
      }

      // console.log(arrayOfLastXDays(7));

      // const lastWeekDailyCounts = logbook.records.filter((record) => arrayOfLastXDays(2).includes(record.qso_date));


      // console.log(lastWeekDailyCounts.length)

      // create an array of the uniq grids
      const seenGrids = logbook.records.map(record => record.gridsquare).filter((grid, idx, self) => grid && grid.length > 0 && self.indexOf(grid) === idx);

      // console.log(logbook.records[44])
      // console.log(logbook.records.map(record => record.gridsquare));

      setSeenGrids(seenGrids);



      // const startingMemo = arrayOfLastXDays(30).reduce(
      //   (memo: { [key: string]: number }, date) => {
      //     memo[date] = 0;
      //     return memo;
      //   }, {}
      // );

      const tmp30 = arrayOfLastXDays(31);


      const startingMemo = tmp30.reduce(
        (memo: { [key: string]: any }, date) => {
          memo[date] = {
            "160m": 0,
            "80m": 0,
            "60m": 0,
            "40m": 0,
            "30m": 0,
            "20m": 0,
            "17m": 0,
            "15m": 0,
            "12m": 0,
            "10m": 0,
            "6m": 0
          }
          return memo;
        }, {}
      );



      const statsForLast30Days = logbook.records.filter((record) => tmp30.includes(record.qso_date)).reduce((memo: any, record) => {
        if (record.distance) {
          // if (!memo[record.qso_date]) {
          //   memo[record.qso_date] = {
          //     "160m": 0,
          //     "80m": 0,
          //     "60m": 0,
          //     "40m": 0,
          //     "30m": 0,
          //     "20m": 0,
          //     "17m": 0,
          //     "15m": 0,
          //     "12m": 0,
          //     "10m": 0,
          //     "6m": 0
          //   }
          // }
          // console.log("record.band", record.band);
          if (!!record.band) {
            memo[record.qso_date][record.band.toLowerCase()] = memo[record.qso_date][record.band.toLowerCase()] + 1;
          }
        }
        return memo;
      }, startingMemo);

      console.log('statsForLast30Days', statsForLast30Days);
      // return;

      setDailyStats(Object.keys(statsForLast30Days).map(date => {
        // console.log(statsForLast30Days);
        // needs to be new Date("2024-04-12"), but right now the string "20240412"
        const dateObj = new Date(date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8));
        return {
          date: dateObj,
          "160m": statsForLast30Days[date]["160m"],
          "80m": statsForLast30Days[date]["80m"],
          "60m": statsForLast30Days[date]["60m"],
          "40m": statsForLast30Days[date]["40m"],
          "30m": statsForLast30Days[date]["30m"],
          "20m": statsForLast30Days[date]["20m"],
          "17m": statsForLast30Days[date]["17m"],
          "15m": statsForLast30Days[date]["15m"],
          "12m": statsForLast30Days[date]["12m"],
          "10m": statsForLast30Days[date]["10m"],
          "6m": statsForLast30Days[date]["6m"]
        }
      }))

      const seenCountriesTmp: SeenCountries = logbook.records.filter((record) => true || arrayOfLastXDays(30).includes(record.qso_date)).reduce((memo: SeenCountries, record) => {
        // if (record.dxcc === '052' || record.dxcc === '52') {
        //   console.log(record);
        // }
        const confirmed = recordConfirmed(record);
        if (!memo.hasOwnProperty(record.country)) {
          // console.log("new country: ", record.country);
          memo[record.country] = { dxcc: record.dxcc, confirmed: confirmed ? 1 : 0, unconfirmed: confirmed ? 0 : 1, total: 1 };
        } else {
          memo[record.country] = {
            dxcc: record.dxcc,
            confirmed: memo[record.country].confirmed + (confirmed ? 1 : 0),
            unconfirmed: memo[record.country].unconfirmed + (confirmed ? 0 : 1),
            total: memo[record.country].total + 1
          };
        }
        return memo;
      }, {});
      const seenCountriesArrayTmp = Object.keys(seenCountriesTmp).map((country) => {
        return {
          name: country,
          dxcc: seenCountriesTmp[country].dxcc,
          confirmed: seenCountriesTmp[country].confirmed,
          unconfirmed: seenCountriesTmp[country].unconfirmed,
          total: seenCountriesTmp[country].total
        }
      });
      setSeenCountriesArray(doSort(seenCountriesArrayTmp));
    } else {
      // console.log("not a map or array");
    }

  }, [logbook])

  useEffect(() => {
    if (seenCountriesArray) {
      setSeenCountriesArray(doSort(seenCountriesArray));
    }
  }, [sort]);

  console.log("dailystats", dailyStats);

  return (
    <>
      {!logbook?.header && (
        <Flex alignItems="center" direction="column">
          <FileTrigger
            allowsMultiple={false}
            acceptDirectory={false}
            onSelect={(e) => {
              if (e && e.length > 0) {
                const file = e[0];
                if (file.name.endsWith(".adi") || file.name.endsWith("adif")) {
                  file.text().then(text => {
                    setLogbook(AdifParser.parseAdi(text))
                  })
                } else {
                  //console.log("not a good file");
                }
              }
            }}>
            <Button variant="primary">Browse for ADIF log file</Button>
          </FileTrigger>
        </Flex>
      )}
      {logbook?.header && (
        <Flex gap="size-100" direction="column">
          <View height={"size-5000"}>
            <GridMap grids={seenGrids} />
          </View>
          <View height={"size-3000"}>
            <ResponsiveBar
              data={dailyStats}
              keys={[
                "160m",
                "80m",
                "60m",
                "40m",
                "30m",
                "20m",
                "17m",
                "15m",
                "12m",
                "10m",
                "6m"
              ]}
              indexBy="date"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.6
                  ]
                ]
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                format: (v) => {
                  return new Date(v).toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
                },
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Date',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Logged QSO Count',
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.6
                  ]
                ]
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              role="application"
              ariaLabel="QSO Count by Date"
              barAriaLabel={e => e.id + ": " + e.formattedValue + " on date: " + e.indexValue}
            />
          </View>
          <TableView
            aria-label="Seen Countries"
            height="100vh"
            onSortChange={(descriptor) => {
              setSort(descriptor);
            }}
            sortDescriptor={sort}
          >
            <TableHeader>
              <Column key="name" allowsSorting>Name</Column>
              <Column key="confirmed" allowsSorting>Confirmed</Column>
              <Column key="unconfirmed" allowsSorting>Unconfirmed</Column>
              <Column key="total" allowsSorting>Total</Column>
            </TableHeader>
            <TableBody>
              {seenCountriesArray.map((item) => (
                <Row key={item.name}>
                  <Cell><Text UNSAFE_style={{ color: item.confirmed > 0 ? 'blue' : 'orange' }}>{item.name}</Text></Cell>
                  <Cell>{item.confirmed}</Cell>
                  <Cell>{item.unconfirmed}</Cell>
                  <Cell>{item.total}</Cell>
                </Row>
              )).concat([<Row>
                <Cell><Text UNSAFE_style={{ fontWeight: "bold" }}>Total</Text></Cell>
                <Cell><Text UNSAFE_style={{ fontWeight: "bold" }}>{seenCountriesArray.reduce((memo, country) => memo + country.confirmed, 0)}</Text>
                </Cell>
                <Cell><Text UNSAFE_style={{ fontWeight: "bold" }}>{seenCountriesArray.reduce((memo, country) => memo + country.unconfirmed, 0)}</Text>
                </Cell>
                <Cell><Text UNSAFE_style={{ fontWeight: "bold" }}>{seenCountriesArray.reduce((memo, country) => memo + country.total, 0)}</Text>
                </Cell>
              </Row>])}
            </TableBody>
          </TableView>
        </Flex>
      )
      }
    </>
  )
}